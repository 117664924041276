import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd'
import PlayIcon from '../images/PlayIcon.svg'
import DegradedHome from '../images/DegradedHome.svg'

const Presentation = ({ text, socialButtons, image }) => {
   const [isOpen, setOpen] = useState(false)
   const [isEmpresaPage, setIsEmpresaPage] = useState(false);

    useEffect(() => {
        setIsEmpresaPage(window.location.pathname === '/empresas');
    }, []);

   return (
      <Row justify="center" className="presentation__main-content">
         <Col lg={24} className="main-content__degraded-shape" >
            <Row>
               <Col lg={14} xs={24} className="main-content__text">
                  <Row justify="end" className="main-content__text-wrapper">
                     <Col lg={20} xs={22}>
                        {text}
                     </Col>
                      {
                          isEmpresaPage && (
                          <Col lg={20} xs={22}>
                              <Row justify="start">
                                  <Col xl={15} lg={19} xs={24}>
                                      <Button className="main__button" size="large" block>
                                          <a href="#contact">Contáctanos</a>
                                      </Button>
                                  </Col>
                              </Row>
                          </Col>
                      )}
                     {
                        socialButtons ? 
                        <Col lg={20} xs={22} style={{ marginTop: 35 }}>
                           {socialButtons}
                        </Col>
                        : 
                        <div></div>
                     }
                  </Row>
               </Col>
               <Col lg={10} xs={24}
                  className="main-content__image"
                  style={{
                     backgroundImage: `url('${image}')`,
                     backgroundSize: 'cover',
                     backgroundPosition: '0.1cm -0.8cm',
                     backgroundRepeat: 'no-repeat',
                     height: '90vh',
                     width: '80%'
                  }}>
               </Col>
            </Row>
         </Col>
         <Col lg={24} className="main-content__wave">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
            </svg>
         </Col>
      </Row>
   )
}

export default Presentation